.menu-icon {
  height: 2rem;
  width: 2rem;
}
.menu-item {
display: flex;
flex-direction: column;
align-items: center;
padding: 1rem;
color: white;
font-family: D-DIN;
justify-content: space-between;
height: 3.1rem;

}
.hidden {
  opacity: 0;
}

.inherit-width {
  width: inherit;
}

.menu-popup {
  position: fixed;
  width: 100%;
  top: 30px;
  background-color: #898989;
  transition: 200ms;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
}
