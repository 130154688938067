
.text-subtitle {
  font-family: "D-DIN";
  font-size: 15px;
  color: white;
  padding: 5px;
}

@font-face {
  font-family: retro_gaming;
  src: url("Retro Gaming.ttf");
}

@font-face {
  font-family: D-DIN;
  src: url("D-DIN.otf");
}

.menu-text {
  line-height: 0.2;
}
.text-title {
  line-height: 1;
}


p,
h2 {
  color: #cec4ff;
  font-size: 16px;
  font-family: D-DIN;

}
.flex {
  display: flex;
}


h3 {
  font-size: 24px;
  color: #cec4ff;
  font-family: D-DIN;
  font-style: italic;
}