* {
  scroll-behavior: smooth;
}
body {
  margin: 0;
  background-color: #342b79;
  width: 100%;
  height: 200vh;
  overflow-x: hidden;
  font-family: retro_gaming;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-column {
  display: flex;
  flex-direction: column;
}

.space-between {
  justify-content: space-between;
}

.space-around {
  justify-content: space-around;
}

.align-center {
  align-items: center;
}

.justify-center {
  justify-content: center;
}

.align-end {
  align-items: end;
}

.center-text {
  text-align: center;
}

.bottom-align {
  bottom: 12.66666%;
}

.fade-out-begin {
  opacity: 0;
}

.fade-out-end {
  opacity: 1;
}

.curtain {
  height: 100vh;
  width: 100vw;
  background-color: #342b79;
  transition: 600ms;
  transition-delay: 200ms;
}

.page {
  min-height: 100vh;
}

.grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  column-gap: 25px;
  row-gap: 10px;
}

a {
  text-decoration: none;
}

.responsive-flex-box {
  display: flex;
  flex-wrap: wrap;
  gap: 130px;
  justify-content: space-around;
}
@media (max-width: 300px) {
  .responsive-flex-box {
    flex-direction: column;
  }
}

.top-container {
  position: relative;
  display: flex;
  flex-direction: column;
  transform: translateY(-98%);
}

h2 {
  font-size: 20px;
  text-align: left;
  max-width: 100px;
}
h1 {
  color: #FFF500
}

p {
  text-align: justify;
}
